<template>
  <div class="box">
    <!-- 左侧图片 -->
    <div class="left" v-if="!selectState.isCustom">
      <div class="tucheng flex_align">
        <div class="tucheng_box flex_align" style="width: 80px;padding-left: 5px;">
          <img src="../assets/map/dj.png" alt="" />
          <!-- <img v-if="onLineNum == 1" src="../assets/map/dj.png" alt="" />
          <img v-else src="../assets/map/dj1.png" alt="" /> -->
          <el-select class="tucheng_box_qb" style="flex:1;" v-model="onLineNum" :placeholder="$t('message.online')"
            @change="changeValue" :clearable="false">
            <el-option v-for="item in onLine" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="tucheng_box flex_align mouse" @click="changeMap(1)">
          <img v-if="isShow" src="../assets/map/xs.png" alt="" />
          <img v-else src="../assets/map/xs1.png" alt="" />
          {{ isShow ? $t('message.Show') : $t('message.Hide') }}
        </div>
        <div class="tucheng_box flex_align mouse" @click="changeMap(2)">
          <img v-if="mapType" src="../assets/map/gj.png" alt="" />
          <img v-else src="../assets/map/gj1.png" alt="" />
          {{ mapType ? $t('message.Default') : $t('message.Satellite') }}
        </div>
        <div class="tucheng_box flex_align mouse" @click="changeMap(3)">
          <img src="../assets/map/hld.png" alt="" />
          <span :class="isTraffic ? 'ls' : ''">{{ $t('message.Roadconditions') }}</span>
        </div>
        <div class="tucheng_box flex_align" style="flex:1;padding-left: 5px;">
          <img src="@/assets/map/gjb.png" alt="" />
          <!-- 工具箱 -->
          <el-select v-if="isMark" style="width:80px;" ref="loanStatusSelect" v-model="holdAllvalue"
            :placeholder="$t('message.Toolbox')">
            <el-option v-for="item in holdAll" :key="item.value" :label="item.label" :value="item.value">
              <div v-if="item.value != 2" @click.stop="mapHoldall(item.value)" class="flex_align"
                style="padding:0px 15px;">
                <!-- style="position: relative;" -->
                <img :src="item.img" style="width:19px;height:16px;margin-right:5px;" />
                <div>{{ item.label }}</div>
              </div>
              <el-popover popper-class="mark_popover" v-if="item.value == 2" transition="el-collapse-transition"
                placement="right-start" :open-delay="500" :offset="0" :close-delay="200" trigger="hover"
                :visible-arrow="false">
                <div slot="reference" @click.stop="mapHoldall(item.value)" class="flex_align" style="padding:0px 15px;">
                  <!-- style="position: relative;" -->
                  <img :src="item.img" style="width:19px;height:16px;margin-right:5px;" />
                  <div>{{ item.label }}</div>
                </div>
                <div class="mark" v-if="markList.length > 0">
                  <div class="mouse mark_box flex_align" v-for="(items, index) in markList"
                    @click="oneselfPosition(items)" :key="index">
                    <img style="margin-right:5px;"
                      :src="items.img ? xinextendUrlapi + '/icon' + items.img : require('@/assets/map/mark.png')"
                      alt="" />
                    {{ items.name }}
                  </div>
                </div>
              </el-popover>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="fangda mouse">
        <div class="fangda_icon" style="border-bottom: 1px solid #ccc;" @click="changeMap(4, true)">
          <img src="../assets/map/jh.png" alt="" />
        </div>
        <div class="fangda_icon" @click="changeMap(4, false)">
          <img src="../assets/map/jh1.png" alt="" />
        </div>
      </div>
      <div class="fangda_one flex_align mouse" @click="oneselfPosition()">
        <img src="../assets/map/dw1.png" alt="" />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="overall">
      <div class="overall-vertical">
        <div class="horizontal mouse">
          <div class="overall-horizontal">
            <div :class="['icon', selectState.isCustom ? 'overall_one' : '']" style="position: relative;">
              <div class="icon_bg" v-if="draw.isPolygon" @click="getselectIcon(4, 1)">
                <img v-if="selectState.isCustom" src="../assets/map_zx1.png" alt="" />
                <img v-else src="../assets/map_zx.png" alt="" />
                <div class="triangle" @click.stop="bindSelect"></div>
              </div>
              <div class="icon_bg" v-if="draw.isCircular" @click="getselectIcon(5, 1)">
                <img v-if="selectState.isCustom" src="../assets/map_yx1.png" alt="" />
                <img v-else src="../assets/map_yx.png" alt="" />
                <div class="triangle" @click.stop="bindSelect"></div>
              </div>
              <!-- 方形 -->
              <div class="icon_bg" v-if="draw.isSquare" @click="getselectIcon(6, 1)">
                <img v-if="selectState.isCustom" src="../assets/map_fx1.png" alt="" />
                <img v-else src="../assets/map_fx.png" alt="" />
                <div class="triangle" @click.stop="bindSelect"></div>
              </div>
              <!-- v-if="mapIconDisplay" -->
              <div class="Drawapicture" v-show="functionIconHeng">
                <div>
                  <img src="../assets/map_zx2.png" @click="getselectIcon(4)" alt="" />
                </div>
                <div>
                  <img src="../assets/map_yx2.png" @click="getselectIcon(5)" alt="" />
                </div>
                <div>
                  <img src="../assets/map_fx2.png" @click="getselectIcon(6)" alt="" />
                </div>
              </div>
            </div>
            <div class="icon">
              <div class="icon_bg" @click="getselectIcon(2)">
                <img src="../assets/map_fh.png" alt="" />
              </div>
            </div>
            <div class="icon">
              <div class="icon_bg" @click="getselectIcon(3)">
                <img src="../assets/map_sc.png" alt="" />
              </div>
            </div>
          </div>
          <!-- <img class="horizontal_img" @click="bindOpenGb('广播')" src="../assets/radiobroadcast.png" alt="" /> -->
        </div>
      </div>
    </div>
    <!-- 对话框 -->
    <dialogbox ref="dialogbox" :dialogTitle="dialogTitle" :openGb="openGb" :width="dialogWidth"></dialogbox>
  </div>
</template>
<script></script>
<script>
import dialogbox from "@/components/dispatchLntercom/dialogbox.vue";
import EventBus from "@/eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js";
// import { EleResize } from "../administration/esresize.js";
export default {
  components: {
    dialogbox
  },
  name: "mapcustomfence",
  data() {
    return {
      search: "",
      selectIcon: 0,
      functionIconHeng: false,
      functionIconSu: false,
      timeout: null,
      holdAll: [
        //工具箱
        // {
        //   value: 0,
        //   label: "工具箱",
        //   img:require("@/assets/map/gjb.png"),
        //   imgBg:require("@/assets/map/gjb1.png")
        // },
        {
          value: 1,
          label: this.$t('message.Ranging'),
          img:require("@/assets/map/cg.png"),
          imgBg:require("@/assets/map/cg1.png")
        },
        {
          value: 2,
          label: this.$t('message.Mark'),
          img:require("@/assets/map/bz.png"),
          imgBg:require("@/assets/map/bz1.png")
        }
      ],
      holdAllIndex:0,
      onLine: [
        //在线状态
        {
          label: this.$t('message.all'),
          value: 0
        },
        {
          label: this.$t('message.online'),
          value: 1
        },
        {
          label: this.$t('message.offline'),
          value: 2
        }
      ],
      onLineNum: 1, //在线状态
      isShow: true, //显示 状态
      mapType: true, //地图显示类型
      isTraffic: false,
      holdAllvalue: this.$t('message.Toolbox'),
      openGb: false,
      dialogWidth: "956px", //对话框的宽度  50%
      dialogTableVisible: false,
      dialogTitle: "",
      selectState: {
        //
        isCustom: false, //自定义选中
        isReturn: false, //返回选中
        isDelete: false //删除选中
      },
      location: {
        lng: "",
        lat: ""
      },
      ac: {},
      draw:{
         isPolygon:true,
         isCircular:false,
         isSquare:false,
      },
      xinextendUrlapi:beasconfig.xinextendUrlapi,
      isMark:true
    };
  },
  props: {
    mapIconDisplay: {
      type: Boolean,
      default: true
    },
    baiduMap: {},
    markList: {
          type: Array,
          default: function() {
			       return []
		      }
    }
  },
  created() {
    let that = this;
    that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  mounted() {},
  filters: {},
  methods: {
    mapHoldall(e){   //改变地图
          // this.holdAllIndex = e;
          this.$refs.loanStatusSelect.visible = false
          // EventBus.$emit("mapHoldall",e)
          if(e == 1){
            this.$emit("changeMap", 5);
          }else if(e == 2){
            this.$emit("changeMap", 6);
          }
    },
    changeValue(e) {
      //选中值
      this.$emit("switchOnlineState", e, this.onLine[e].label);
    },
    changeMap(type, bool) {
      //改变地图
      if (type == 1) {  //改变地图状态
        this.isShow = !this.isShow;
        this.$emit("changeMap", type, this.isShow);
      } else if (type == 2) {  //切换地图类型
        this.mapType = !this.mapType; 
        this.$emit("changeMap", type, this.mapType);
      } else if (type == 3) {   //创建交通流量图层实例
        this.isTraffic = !this.isTraffic;
        this.$emit("changeMap", type, this.isTraffic);
      } else if (type == 4) {   //地图放大缩小
        this.$emit("changeMap", type, bool);
      }
    },
    bindSelect() {
      //选中
      if(this.selectState.isCustom){

        return
      }
      this.functionIconHeng = !this.functionIconHeng;
      // this.$emit("openDrawing");
    },
    getselectIcon(type,num) {
      // console.log("type", type);
      this.selectIcon = type;
      this.functionIconHeng = false;
      if(type >= 4 && this.selectState.isCustom){

        return
      }
      if (type == 1) {
        this.functionIconHeng = true;
        this.functionIconSu = true;
        this.$emit("openDrawing");
      } else if (type == 2) {
        this.selectState.isCustom = false;
        this.draw = {
          isPolygon:true,
          isCircular:false,
          isSquare:false,
       }
        this.$emit("previousStep");
      } else if (type == 3) {
        this.$emit("deleteFenceArea");
      } else if (type == 4) {
          if(num == 1 && this.selectState.isCustom){
            this.selectState.isCustom = false;
            this.$emit("openDrawing");
          }else{
            this.selectState.isCustom = true;
            this.draw = {
            isPolygon:true,
            isCircular:false,
            isSquare:false,
          }
          this.$emit("drawPolylines");
          }
      } else if (type == 5) {
        if(num == 1 && this.selectState.isCustom){
          this.selectState.isCustom = false;
          this.$emit("openDrawing");
        }else{
          this.selectState.isCustom = true;
          this.$emit("drawCircle");
            this.draw = {
              isPolygon:false,
              isCircular:true,
              isSquare:false,
          }
        }
      } else if (type == 6) {
        if(num == 1 && this.selectState.isCustom){
          this.selectState.isCustom = false;
          this.$emit("openDrawing");
        }else{
          this.selectState.isCustom = true;
          this.draw = {
              isPolygon:false,
              isCircular:false,
              isSquare:true,
          }
           this.$emit("drawRectangle");
        }
      }
    },
    oneselfPosition(row){    //当前位置
          EventBus.$emit('position',row);
          if(row){
            this.isMark = false;
            this.$nextTick(()=>{
              this.isMark = true;
            })
          }
    },
    bindOpenGb(title) {
      //显示广播
      if(this.selectState.isCustom){

        return
      }
      let groupArray = JSON.parse(sessionStorage.getItem("groupArray"));
      let array = groupArray.filter(item => item.isRadioBroadcast == true);
      if (array && array.length < 1) {
        this.$MessageWarning(`请先开启接收广播的群组`);
      } else {
        this.$refs.dialogbox.openPg(2);
        this.openGb = true;
        this.dialogWidth = "956px";
        this.dialogTitle = title;
      }
      EventBus.$emit("emptyMap",1);
    },
    query() {
      
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.left {

  // position: absolute;
  // top: 10px;
  // left: 10px;
  .search {
    width: 455px;
    position: absolute;
    top: 10px;
    left: 10px;
    // width: 418px;
    height: 40px;
    overflow: hidden;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px;

    &_x {
      width: 50px;
      height: 100%;
      line-height: 40px;
      border-left: 1px solid #e2e2e2;
      color: #ccc;
      font-size: 30px;
      font-weight: 400;
    }

    &_input {
      flex: 1;
      height: 100%;
      background-color: #fff;
      color: #696969 !important;

      /deep/.el-input__inner {
        width: calc(100% - 42px);
        // width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        color: #696969 !important;
        border: none;
        border-radius: 0px;
        padding: 0px;
      }
    }

    &_but {
      width: 58px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #327676;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  .tucheng {
    // width: 400px;
    // width: 359px;
    min-width: 457px;
    height: 36px;
    background-color: #ffffff;
    font-size: 14px;
    color: #343434;
    box-shadow: 0px 1px 5px 0px #343434;
    border-radius: 5px;
    margin-top: 10px;
    position: absolute;
    top: 55px;
    left: 10px;
    overflow: hidden;

    &_box {
      // flex: 1;
      // width: 70px;
      min-width: 70px;
      padding: 0px 5px;
      text-align: center;
      justify-content: center;
      height: 35px;
      border-right: 1px solid #e2e2e2;

      /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 35px;
        line-height: 35px;
        background-color: #fff;
        color: #696969 !important;
        border: none;
        border-radius: 0px;
        padding: 0px;
      }

      /deep/.el-input__suffix {
        right: 0px;
      }

      img {
        width: 19px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }

  .fangda {
    width: 34px;
    height: 60px;
    font-size: 30px;
    background-color: #ffffff;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 8px 0px #666666;
    color: #000000;
    margin-top: 10px;
    position: absolute;
    top: 105px;
    left: 10px;

    &_icon {
      width: 85%;
      height: 30px;
      line-height: 20px;
      margin: auto;
      // img{
      //   width: 25;
      // }
    }
  }

  .fangda_one {
    width: 34px;
    height: 34px;
    margin-top: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 8px 0px #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 175px;
    left: 10px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.overall {
  // height: 100%;
  position: absolute;
  top: 10px;
  // top: 4px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;

  .horizontal {
    display: flex;
    align-items: center;

    &_img {
      width: 120px;
      height: 54px;
    }

    .mian-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
    }

    .overall-horizontal {
      width: 190px;
      height: 40px;
      // overflow: hidden;
      display: flex;
      align-items: center;
      background: #fff;
      margin-left: 8px;
      margin-right: 10px;
      border: 1px solid var(--main-color);
      border-radius: 5px;
      box-shadow: 0 0 5px 0px var(--main-color);

      .overall_one {
        background-color: var(--main-color);
      }

      .icon {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e2e2e2;
        position: relative;

        &:last-child {
          border: none;
        }

        &:hover {
          background-color: var(--main-color);
        }

        .Drawapicture {
          position: absolute;
          bottom: -115px;
          z-index: 10;
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          overflow: hidden;

          div {
            width: 40px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            border-bottom: 1px solid #e2e2e2;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }

        &_bg {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          // overflow: hidden;
          .triangle {
            width: 0px;
            border-style: solid;
            border-color: transparent;
            border-width: 8px 8px 8px 8px;
            // border-width: 5px 5px 5px 5px;
            border-right-color: #414447;
            border-bottom-color: #414447;
            opacity: 1;
            margin: auto;
            position: absolute;
            right: 0px;
            bottom: 0px;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }

        .ace1 {
          background: url("../assets/map_zx.png");
          background-size: 100% 100%;
        }

        .ace2 {
          background: url("../assets/map_fh.png");
          background-size: 100% 100%;
        }

        .ace3 {
          background: url("../assets/map_sc.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  display: flex;
  align-items: flex-end;

  &_left {
    position: fixed;
    bottom: 0px;
    left: 55%;

    .triangle {
      width: 0px;
      border-style: solid;
      border-color: transparent;
      border-width: 8px 8px 8px 8px;
      border-top-color: #414447;
      opacity: 1;
      margin: auto;
      position: relative;

      &_border {
        width: 0px;
        border-style: solid;
        border-color: transparent;
        border-width: 9px 9px 9px 9px;
        border-top-color: var(--main-color);
        position: absolute;
        top: -8px;
        left: -9px;
        z-index: -1;
      }
    }

    .bottom_ptt {
      width: 107px;
      height: 107px;
      margin: auto;
    }
  }

  &_popup {
    width: 180px;
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
    text-align: center;
    position: relative;
    background-color: #414447;
    opacity: 0.9;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    top: -15px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }

  &_tz {
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
.mark {
  min-width: 125px;
  max-height: 180px;
  background-color: #ffffff;
  overflow: auto;
  // padding: 10px 0px;

  // position: absolute;
  // right: 0px;
  // top: 0px;
  &>div:hover {
    background-color: #e2e2e2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #fff;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  &>div {
    padding: 5px 10px;

    &>img {
      width: 25px;
      height: 25px;
    }
  }
}
.ls {
  color: #4fd17d;
}

.flex_align {
  display: flex;
  align-items: center;
}
</style>
<style>
.mark_popover {
  min-width: 120px !important;
  padding: 0px !important;
  margin-left: 20px !important;
}
</style>