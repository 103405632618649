
module.exports = {
  language: {
    name: 'English',
    title: 'language',
  },

  about: {
    title: 'Global intercom dispatcher',
    conpanyname: 'Quanzhou Global-ptt Co., Ltd.',
    website:'website',
  },
  login: {
    account: 'Enter login account',
    password: 'enter password',
    installationServices: 'Install Speech Services',
    rememberme: 'remember me',
    Login: 'Log in',
    testaccount:'test account',
    Password:'Password'
  },
  message: {
    loginAccountTips: 'Login account cannot be empty',
    loginPasswordTips: 'password can not be blank',
    usernamePasswordTips: 'Incorrect username or password',
    loginsuccessful: 'login successful',
    accountNumbers: 'The account can only enter numbers!',
    noDispatcher: 'Login failed, the account is not a dispatcher!',
    accountNotpresent: 'Account does not exist!',
    LoginAbnormal: 'Login exception, please try again!',
    voiceServiceNotInstalled: 'Please install the voice service, if installed, please refresh the browser!',
    Videocallinprogress: 'Video call in progress!',
    Voicecallinprogress: 'Voice call in progress!',
    Youhavebeenclosedforspeaking: 'You have been turned off for speaking!',
    tctemporarygroup: 'Are you sure to refresh and exit the temporary group?',
    Doyouwanttologout: 'Do you want to log out?',
    prompt: 'hint',
    Nonetworklink: 'no internet connection',
    deletedGro: 'This group has been deleted and cannot be operated!',
    Pleaseselectthedesired: 'Please select the required',
    deletedGroUsers: 'User!',
    Voicecallfunction: 'Please enable the voice call function on the management platform first!',
    Alreadytemporary: 'Already in a temporary group, unable to call!',
    Onlysupports: 'Only supports voice calls to a single user!',
    Enablevoicecalls: 'This user has not enabled the voice call function on the management platform!',
    Oncall: 'Cannot create while in call!',
    Shaketodeath: 'Shaking dead, cannot create',
    Shaketodeath2: 'Shaking dead, cannot switch groups',
    createregiongroups: 'You can only create up to 3 regional groups!',
    temporarygroupsnoadd: 'Already in a temporary group, cannot be created!',
    haleDispatcherGroupUser: "Only members of the dispatcher's group can be forced",
    TemporaryGroupNoHale: 'Temporary group, do not force pull!',
    Groupcallfailure: 'Group call failed',
    Exitsuccessfully: 'exit successfully!',
    Usernotonline: 'User is offline!',
    Theotherpartyisbusy: 'The other party is busy!',
    Callfailed: 'Call failed!',
    Theotherpartyhashungup: 'The other party has hung up!',
    Networkconnectionfailed: 'Network connection failed, clear and try again later',
    Callended: 'call ended!',
    Accountpassworderror: 'account password error',
    Arrears: 'in arrears',
    Accountdoesnotexist: 'account does not exist',
    Permissionerror: 'permission error',
    AccountInfoChange: 'Account information has changed',
    Configuration: 'Configuration information error',
    accountOtherlogins: 'The account is already logged in elsewhere!',
    Accountlogintimeout: 'Account login timed out',
    Networkconnectionfailure: 'Network connection failed',
    Thenetworkisreconnecting: 'The network is reconnecting',
    Addinggroupfailed: 'Failed to join group',
    Addgrouprequesttimeout: 'Join group request timed out',
    Singlecallfailure: 'single call failure',
    Failedtojointhegroup: 'Failed to join group [permission error]',
    Failedtojointhegroup2: 'Failed to join group [does not exist]',
    Failedtojointhegroup3: 'Failed to join group [Expired]',
    Failedtojointhegroup4: 'Failed to join group [quota limit]',
    Failedtojointhegroup5: 'Failed to join group [valid object is empty]',
    noSnatchingwheat: 'Grabbing wheat multiple times in a short period of time is not allowed',
    noRemoteclosureSnatchingwheat: 'Grabbing microphones in remote closed state is not allowed',
    Failedtograbthewheat: 'Failed to apply for audio focus when grabbing microphones',
    Grabbingwheattimeout: 'Timed out when receiving the mic response',
    Failedtoopenrecordingdevice: 'Failed to open recording device',
    Accountlocked: 'account is locked',
    Terminallocked: 'Terminal is locked',
    Machinecardlocked: 'card is locked',
    Noaccountinformationconfigured: 'No account information configured',
    Successfullysaved: 'Saved successfully!',
    Successfullysethost: 'Set host successfully!',
    Successfullydeleted: 'successfully deleted!',
    Successfullyadded: 'Added successfully!',
    Successfullyremoved: 'Removed successfully!',
    update_successfully: 'update completed',
    Successfullymodified:'Successfully modified!',
    oldpassworderror:'old password error',
    Parametererror:'Parameter error',
    Pleaseselecttheuseryouwanttoadd: 'Please select the user to add!',
    Addingfailed: 'Failed to add, not in the group user list is empty!',
    Pleaseselectuserremoved: 'Please select the user to be removed!',
    Removalfailed: 'Removal failed, user list in group is empty!',
    Prioritysetsuccessfully: 'Priority set successfully',
    Pleaseselectastartandendtime: 'Please select a start and end time',
    endTimeNoStarttime: 'End time cannot be earlier than start time',
    Thestartandendtimesmustbeonthesameday: 'Start time and end time must be on the same day',
    RedefineCustomization: 'The number of points in the custom area is less than 3, please re-draw!',
    Unabletocreatetemporarygroup: 'There are currently no online members, unable to create a temporary group!',
    Pleaseenteroldpassword:'Please enter old password',
    Pleaseenteranewpassword:'Please enter a new password',
    Confirmnewpassword:'Confirm new password',
    noSamePassword:'The old password cannot be consistent with the new password!',
    noSamePasswordTow:'The passwords entered twice are inconsistent!',
    pleaseenterpasswordagain:'please enter password again'
  },
  search: 'search',
  users: 'user',
  refund: 'return',
  Loadingdesperately: 'Desperately loading',
  Speaking: 'talking...',
  Iamspeaking: "I'm speaking...",
  communicatebytelephone: 'call',
  call: 'call',
  hale: 'strong pull',
  Forceddemolition: 'Demolition',
  confirm: 'Sure',
  cancel: 'Cancel',
  dispatchIntercom: 'dispatch intercom',
  allGroups: 'all groups',
  fixedGroup: 'fixed groups',
  regionalGroup: 'regional group',
  filter: 'filter',
  all: 'all',
  online: 'online',
  offline: 'offline',
  groupUser: 'group/user',
  enter: 'Enter',
  edit: 'edit',
  voiceIntercom: 'Voice intercom',
  baseStationSwitch: 'base station switch',
  gpsSwitch: 'GPS switch',
  viewHistoricalTrack: 'View historical track',
  startingtime1: 'Start Time',
  selectionPeriod: 'Select time',
  startingTime: 'Starting time',
  Pleaseselectadateandtime: 'Please select a start time',
  EndTime: 'End Time',
  Pleaseselectanendtime: 'Please select an end time',
  selectDate: 'select date',
  SelectStartTime: 'select start time',
  Now: 'now',
  Sure: 'Sure',
  Closure: 'closure',
  editArea: 'edit area',
  deleteArea: 'delete area',
  Areyousuretosavethefence: 'Are you sure you want to save the fence?',
  deleteLocalgroup: 'Are you sure you want to delete the zone group?',
  pleaseselectaregion: 'Please search or circle the area first!',
  doubleclicktocreate: 'Double click to create',
  doubleclick: 'double click',
  Createregionalgroups: 'Create regional groups',
  groupName: 'group name',
  Pleaseenterthegroupname: 'Please enter a group name',
  pleaseenteragroupname: 'Please enter the group name (composed of 2-15 digits, Chinese and English)',
  groupnameTips: 'The group name consists of 2-15 digits, Chinese and English',
  pleaseenteragroupnameTips: 'Consists of 2-15 digits, Chinese and English',
  selectGroup: 'select group',
  Pinnedingroupusers: 'pinned in group users',
  TemporaryGroup: 'Temporary users in the group',
  Usernotingroup: 'user not in group',
  userImei: 'User/IMEI',
  Pleaseselectauser: 'Please select a user',
  Create: 'create',
  Cancel: 'Cancel',
  groupEdit: 'group edit',
  usersingroup: 'users in group',
  UsernameImei: 'Username/IMEI',
  userName: 'user name',
  noData: 'No data',
  removeselectedusers: 'remove selected users',
  Removeall: 'remove all',
  Addtheselecteduser: 'Add selected users',
  Addall: 'add all',
  Save: 'save',
  selectUser: 'select user',
  Edittakeseffect: 'edit takes effect',
  UserManagement: 'User Management',
  Userpriority: 'user priority',
  levelone: 'level one',
  Secondary: 'level two',
  Levelthree: 'Level three',
  Levelfour: 'Level four',
  Fifthgrade: 'Level five',
  Editstakeeffectimmediately: 'Edits take effect immediately',
  Setmoderator: 'set host',
  SelectHost: 'choose host',
  Host: 'host',
  Pleasechoose: 'please choose',
  Deletemoderator: 'delete host',
  Setuphost: 'Only one user can be set as host',
  Pleaseselectacall: 'Please choose what you want to call',
  Waitingforanswering: 'Wait for the other party to answer the voice',
  Inviteyoutovoicecall: 'invite you to voice call',
  callingusers: 'Please select the user to call',
  Forcedpullusers: 'Please select the user who needs to be pulled',
  Forceddismantlingusers: 'Please select the user who needs to be demolished',
  SearchLocation: 'search location',
  Show: 'show',
  Hide: 'hide',
  Default: 'default',
  Satellite: 'satellite',
  Roadconditions: 'road conditions',
  Toolbox: 'toolbox',
  Ranging: 'ranging',
  Mark: 'mark',
  Broadcast: 'broadcast',
  EnableBroadcastGroup: 'Please enable the group to receive broadcasts first',
  textBroadcast: 'text broadcast',
  Hasbeensent: 'Has been sent',
  textBroadcastContent: 'Please enter the content of the text broadcast (no more than 50 characters)',
  pleaseentertextbroadcast: 'please enter text broadcast',
  Send: 'send',
  voiceBroadcast: 'voice broadcast',
  Clicktorecordvoicebroadcast: 'Click to record voice broadcast',
  Pleaserecordthevoicebroadcast: 'Please record the voice broadcast',
  RecordingTime: 'The recording time cannot be less than 1 second',
  Broadcaster: 'Broadcaster',
  BroadcastTime: 'broadcast time',
  listento: 'listen to',
  Warningmessage: 'warning message',
  AlarmHistory: 'For more warning messages, please check the history',
  Name: 'name',
  Address: 'address',
  Time: 'time',
  Check: 'Check',
  State: 'state',
  Unprocessed: 'unprocessed',
  DealWith: 'deal with',
  delete: 'delete',
  PTTbuttondisplay: 'PTT button display',
  SOSalarmsound: 'SOS alarm sound',
  Fencealarmsound: 'fence warning sound',
  Motiontrajectorybinding: 'Motion track tie road',
  Changepassword: 'change Password',
  Logoutoflogin: 'sign out',
  Dataanalysis: 'data analysis',
  Statisticstoday: 'Statistics today',
  Currentonlineusers: 'Current online users',
  Totalnumberofusers: 'user total',
  Fencerecord: 'fence record',
  SOSrecord: 'SOS record',
  Grouponlineusers: 'group online users',
  Comparison: 'Comparison of the number of online users in 7 days',
  warningcomparison: '30-day early warning comparison',
  Fencewarning: 'fence warning',
  SOSwarning: 'SOS warning',
  OldPassword:'Old Password',
  NewPassword:'New Password',
  ConfirmPassword:'Confirm Password',
  consists:'Consists of 6-16 letters and numbers',
  startingpoint:'starting point',
  totallength:'total length',
  ico:'ico',
  date:'date',
  Targeting:'Targeting',
  Basestationpositioning:'Base station positioning',
  BeidouGPS:'Beidou/GPS',
  direction:'direction',
  altitude:'altitude',
  Speed:'Speed(KM/H)',
  warmreminder:'Warm reminder: The data comes from a third party and may contain errors. It is for reference only.',
  Iconselection:'Icon selection',
  pleaseentername:'Please enter name',
  changeicon:'Change icon',
  Universal:'Universal',
  policesecurity:'police security',
  Firefighting:'Firefighting',
  Chemicalindustry:'Chemical industry',
  other:'other',
  save:'Save',
  close:'Close',
  viewtag:'View tag',
  addtag:'Add tag',
  Supportsaddingamaximum:'Supports adding a maximum of 10 icons',
  Imageformaterror:'Image format error',
  uploadedimage:'The size of the uploaded image cannot exceed 5kb!',
  uploadsuccessful:'Upload successful',
  uploadfailed:'upload failed',
  uploadfailedimage:'Upload failed: The maximum pixel size of the image is 25×25!',
  Successfuloperation:'Successful operation',
  operationfailed:'operation failed',
  Editmark:'Edit mark',
  Pleaseselecttheiconyouwanttodelete:'Please select the icon you want to delete',
  successfullydeleted:'successfully deleted',
  failedtodelete:'failed to delete',
  north:'north',
  South:'South',
  West:'West',
  East:'East',
  truenorth:'true north',
  duesouth:'due south',
  duewest:'due west',
  Zhengdong:'Zhengdong',
  partial:' partial',
  historyrecord:'history record',
  group:'group',
  pleaseselectagroup:'Please select a group',
  sender:'sender',
  Pleaseselectuser:'Please select user',
  Sendtime:'Send time',
  startrange:'start range',
  endrange:'end range',
  startdate:'start date',
  Startingtime:'Starting time',
  enddate:'end date',
  Clear:'Clear',
  Confirm:'Confirm',
  state:'state',
  Pleaseselectastatus:'Please select a status',
  Processed:'Processed',
  Processor:'Processor',
  processtime:'process time',
  serialnumber:'serial number',
  operate:'operate',
  ViewSOSlocation:'View SOS location',
  Back:'Back',
  Warmreminder: 'Warm reminder: The data comes from a third party and may contain errors. It is for reference only.',
  SOSalarmsintemporarygroupsarenotrecorded:'SOS alarms in temporary groups are not recorded.',
  Pleaseselectatype:'Please select a type',
  deleted:'deleted',
  next:'next',
  Tobeprocessed:'To be processed',
  dealwith:'deal with',
  alreadydispatched:'Already dispatched',
  temporarygroup:'temporary group',
  NortheasternHemisphere:'Northeastern Hemisphere',
  northwesternhemisphere:'northwestern hemisphere',
  SoutheasternHemisphere:'Southeastern Hemisphere',
  southwesternhemisphere:'southwestern hemisphere',
  Nolocationtrack:'No location track',
  Latitudeandlongitude:'Latitude and longitude',
  Reportingtime:'Reporting time',
  Positioningtime:'Positioning time',
  Alarminformation:'Alarm information',
  AlarminformationTips:'For more alarm messages, please view the history records',
  initiatesos:'initiate sos',
  Forcedvideo:'Forced video',
  videocall:'video call',
  Videopostback:'Video postback',
  leave:'leave',
  Enter:'Enter',
  fence:'fence',
  newgroup:'new group',
  Joingroup:'Join group',
  Thegroupnamealreadyexistspleasereenterit:'The group name already exists, please re-enter it.',
  Groupcreatedsuccessfully:'Group created successfully',
  Groupcreationfailedpleasecreateagain:'Group creation failed, please create again!',
  Pleaseenterthegrouppassword:'Please enter the group password',
  Thegrouppasswordyouenterediswrong:'The group password you entered is wrong',
  Grouppassword:'Group password',
  Dissolve:'Dissolve',
  Exit:'Exit',
  Disbandselforganizedgroup:'Disband self-organized group',
  Exittheselfcreatedgroup:'Exit the self-created group',
  ExiDissolvetheselfestablishedgroupt:'Are you sure you want to dissolve the self -constructing group?',
  Exitselfbuiltgroup:'Are you sure you want to exit the self -built group?',
  Grouptransfer:'Group transfer',
  Theusersselfbuiltgroupfunctionisnotopened: "The user's self -built group function is not opened"
}