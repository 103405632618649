import request from '@/api/request'

// 登录方法
export async function Login(data) {
    return await request({
        url: '/intercom/login',
        method: 'post',
        data: data
    })
}

// 修改密码
export async function updatePassword(data) {
    return await request({
        url: '/intercom/updatePassword',
        method: 'post',
        data: data
    })
}

